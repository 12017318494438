(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.quickDeliveryHomes")
    .controller("QuickDeliveryHomesCtrl", ["$scope", "$window", "CommunitiesListDataService", "$rootScope", "$routeParams", "$location", "$modal",
      function($scope, $window, CommunitiesListDataService, $rootScope, $routeParams, $location, $modal) {

        $scope.tab = 'communities';
        $scope.routeParams = $routeParams;
        $scope.seo_name = $scope.routeParams.seoName;

        $scope.regions = CommunitiesListDataService.regions;

        $scope.mobileTrigger = function(target){
          $(target).focus();
        }

        $scope.mobileHome = function(){
          $location.path('/quick-delivery-homes/' + $scope.seo_name);
        }

        $scope.activeRegion = function() {
          var res = $scope.regions.filter(function(region) {
            return region.seo_name === $scope.seo_name;
          });
          return res[0];
        };

        $scope.selectedTabIndex = 0;

        $scope.selectTab = function(tabIndex) {
          $scope.selectedTabIndex = tabIndex;
        };

        $scope.naturalSort = function(home) {
          return home.community.name.replace(/^The /, '');
        };

        $scope.sortColumn = ['price', '!is_completed', 'estimated_completion_date', 'banner'];
        $scope.reverse = false;

        // got to use this instead of == to support arrays
        var array_equals = function(a, b) {
          // if !a XOR !b then we can assume false
          /*jslint bitwise: true */
          if ( (a === undefined ? 1 : 0) ^ (b === undefined ? 1 : 0) ) {
            return false;
          }

          if (a instanceof Function){
            return a === b;
          }

          if (typeof a === 'string') {
            return a === b;
          }

          return a.length === b.length && a.every(function(v, i) {
              return v === b[i];
            });
        };

        $scope.sort = function(eq, column) {
          if (array_equals($scope.sortColumn, column)) {
            $scope.reverse = !$scope.reverse;
            return;
          }

          $scope.sortColumn = column;
          $scope.reverse = false;
        };

        $scope.mobileSort = function(selected) {
          if (array_equals($scope.sortColumn, selected.filterBy)) {
            $scope.reverse = !$scope.reverse;
            return;
          }

          $scope.sortColumn = selected.filterBy;
          $scope.reverse = false;
        };

        $scope.sortArrows = function(column) {
          var asc = array_equals($scope.sortColumn, column) && !$scope.reverse;
          var desc = array_equals($scope.sortColumn, column) && $scope.reverse;

          var res = {
            'indicator': true,
            'indicator-asc': asc,
            'indicator-desc': desc
          };

          return res;
        };

        $scope.dropdownList = [
          {
            filterName: 'Plan Name (A-Z)',
            filterBy: 'residence.name',
            sort: 'asc'
          },
          {
            filterName: 'Plan Name (Z-A)',
            filterBy: 'residence.name'
          },
          {
            filterName: 'Community Name (A-Z)',
            filterBy: $scope.naturalSort,
            sort: 'asc'
          },
          {
            filterName: 'Community Name (Z-A)',
            filterBy: $scope.naturalSort
          },
          {
            filterName: 'Bed (low to high)',
            filterBy: 'beds'
          },
          {
            filterName: 'Bed (high to low)',
            filterBy: 'beds'
          },
          {
            filterName: 'Bath (low to high)',
            filterBy: 'baths'
          },
          {
            filterName: 'Bath (high to low)',
            filterBy: 'baths'
          },
          {
            filterName: 'Sqft (low to high)',
            filterBy: 'sqft'
          },
          {
            filterName: 'Sqft (high to low)',
            filterBy: 'sqft'
          },
          {
            filterName: 'Price (low to high)',
            filterBy: 'price'
          },
          {
            filterName: 'Price (high to low)',
            filterBy: 'price'
          },
          {
            filterName: 'Availability (low to high)',
            filterBy: 'availability',
            sort: 'asc'
          },
          {
            filterName: 'Availability (high to low)',
            filterBy: 'availability'
          }
        ];

        $scope.regionSort = $scope.dropdownList[0];

        $scope.chooseElevation = function(home, size) {

          if (typeof size !== 'undefined') {
            size = size;
          } else {
            size = 'medium';
          }

          if (home.assets.length > 0) {
            return _.get(home.assets[0].image, size);
          }

          if (home.elevation && _.get(home.elevation.img, size) !== undefined) {
            return _.get(home.elevation.img, size);
          }

          if (home.residence && home.residence.elevations[0] !== undefined && _.get(home.residence.elevations[0].img, size) !== undefined) {
            return _.get(home.residence.elevations[0].img, size);
          }

          // really really default
          return "/images/site/microsite/site-plan-popover-thumb.png";

        };

        $scope.open = function (home) {
          if (home.activate_details_page) {
            $window.open(home.url, '_blank');
            return;
          }

          $scope.home = home;
          $scope.residence = home.residence;

          var modalInstance = $modal.open({
            templateUrl: 'availableHomeModalContent.html',
            controller: 'AvailableHomeModalInstanceCtrl',
            windowClass: 'modal-available-home',
            animation: true,
            scope: $scope
          });
        }

        $scope.gallery = function(home) {
          $scope.home = home;

          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'myModalContent.html',
            controller: 'ModalInstanceCtrl',
            windowClass: 'custom-carousel-modal',
            size: 'lg',
            scope: $scope
          });
        };

      }
    ])

    .controller('AvailableHomeModalInstanceCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {
      if ($scope.residence === undefined) {
        return null;
      }

      $scope.floorplanElevation = $scope.residence;
      $scope.images = $scope.floorplanElevation.elevations;

      $scope.activeTab = 'floorplans';

      $scope.ok = function () {
        $modalInstance.close();
      };
    }])

    .controller('ModalInstanceCtrl', ["$scope", '$modalInstance',
      function ($scope, $modalInstance) {

        $scope.myInterval = false;
        $scope.noWrapSlides = false;

        $scope.cancel = function () {
          $modalInstance.dismiss('cancel');
        };

      }
    ])
  ;

})();
