(function() {

  'use strict';
  /* global angular, _HomeId */

  angular
    .module("Milestone")

    .controller("QuickDeliveryHomesDetailsCtrl", ["$scope", "HomeDataService", "UserDataService", "$modal",
      function($scope, HomeDataService, UserDataService, $modal) {

        // read more functionality
        $scope.readMoreActive = false; // intial read more state

        // toggle read more state
        $scope.setReadMoreActive = function() {
          $scope.readMoreActive = !$scope.readMoreActive;
        }

        $scope.home = null;
        $scope.community = null;
        var home = HomeDataService.home(_HomeId)
          .then(function(home){
            $scope.home = home;
            $scope.community = home.community;
            return home;
          })
          .then(function(){

            $scope.setActiveTab = function() {
              if ($scope.home.residence.interactive_tour_url || $scope.home.residence.floorplans.length > 0) {
                return 'floor-plan';
              } else if ($scope.home.assets.length > 0) {
                return 'gallery';
              } else if ($scope.home.virtual_tour_link || $scope.home.residence.virtual_tour_link) {
                return 'video';
              } else if ($scope.home.home_features) {
                return 'home-features';
              }
            }

            $scope.chooseHomeElevation = function(home) {

              if (home.assets.length > 0) {
                return home.assets[0].image.original;
              }

              // really really default
              return "/images/site/image-coming-soon.jpg";
      
            };



            $scope.activeForm = false;

            $scope.persistentFeatures = {
              show: function (event, type) {
                $scope.activeForm = type;
      
                if (type === 'schedule') {
                  $('.schedule-a-tour-content').velocity('transition.flipYIn', 300, 'easeInQuad');
                  $('.persistent-features-content').addClass('schedule-a-tour-active');
                  $('.persistent-features').addClass('pf-active');
                }
              },
              hide: function (force) {
                if (!force && $scope.activeForm === 'schedule' && $scope.scheduleForm.$dirty) {
                  return;
                }
    
                $('.schedule-a-tour-content').velocity('stop').velocity('transition.flipYOut', 300, 'easeOutQuad');
                $('.schedule-a-tour').removeClass('persistent-feature-active');
                $('.persistent-features-content').removeClass('schedule-a-tour-active');
                $('.persistent-features').removeClass('pf-active');
                $scope.activeForm = false;
              }
            };

            $scope.labels = {
              timeOfTour: 'preferred time of tour'
            };

            $scope.tourTimes = [
              {
                'name': 'in the morning'
              },
              {
                'name': 'in the evening'
              }
            ];

            $scope.schedule = {};
            UserDataService.getUser().then(function (user) {
              $scope.schedule = angular.copy(user);
              $scope.schedule.opt_in = 1;
              $scope.schedule.origin = window.location.href;
              $scope.schedule.type = 'scheduleATour';
              $scope.schedule.communities = [];
              $scope.schedule.communities[0] = $scope.community.id;
              $scope.schedule.form_id = 177544;
              $scope.schedule.guid = window.getLassoGuid();
            });

            $scope.hideScheduleForm = false;
            $scope.showScheduleMessage = false;
            $scope.showScheduleLoader = false;
            $scope.noDate = false;
            $scope.triedScheduleSubmit = false;

            $scope.noDate = function () {
              return $scope.schedule.date_of_tour === null;
            };

            $scope.checkScheduleFormValidation = function (form) {
              $scope.triedScheduleSubmit = true;

              if (!form.$valid) {
                return;
              }

              processScheduleATourForm();
            };

            var processScheduleATourForm = function () {
              $scope.hideScheduleForm = true;
              $scope.showScheduleLoader = true;
              $http({
                method: 'POST',
                url: '/form/to-database',
                data: $.param($scope.schedule), // pass in data as strings
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                } // set the headers so angular passing info as form data (not request payload)
              }).success(function (data) {
                $scope.showScheduleLoader = false;
                if (data.status === 'success') {
                  // Tie lead id and email to clicky analytics data
                  clicky_custom.visitor = {
                    lead_id: data.id,
                    email: data.email,
                    signup: '1'
                  };

                  clicky_custom.goal = {
                    name: 'Schedule A Tour'
                  };

                  // Log action in clicky
                  clicky.goal('2675'); // Logs a "schedule-a-tour" goal hit in clicky
                  clicky.log('/schedule-a-tour/thank-you'); // Logs a fake page hit in clicky

                  // Fire off a Facebook Pixel Code (Lead)
                  fbq('track', 'Lead');

                  // Fire off a Google Analytics goal hit
                  _gaq.push(['_trackPageview', '/ajax-goals/schedule-a-tour']);

                  if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
                    $window.google_trackConversion({
                      google_conversion_id: googleConversionId,
                      google_conversion_language: 'en',
                      google_conversion_format: '3',
                      google_conversion_color: 'ffffff',
                      google_conversion_label: googleConversionLabel,
                      google_remarketing_only: false
                    });
                  }

                  $scope.scheduleMessage = data.message;
                  $scope.showScheduleMessage = true;
                } else {
                  $scope.scheduleMessage = data.message;
                  $scope.showScheduleMessage = true;
                }
              });
            };



            $('.custom-carousel-modal').on('show.bs.modal', function (e) {
              var idx = $(e.relatedTarget).data('idx');

              $('.custom-carousel').carousel(idx);
            });



            var populateLotTemplateObject = function(lot) {
              var res = {
                elevation: chooseElevation(lot),
                price: displayValue(lot, 'price'),
                beds: displayValue(lot, 'beds'),
                baths: displayValue(lot, 'baths'),
                sqft: displayValue(lot, 'sqft')
              };

              return angular.extend(res, lot);
            };

            $scope.showFloorplan = function(seo_name) {
              $location.url('floorplans/' + seo_name);
              $scope.scrollToUrl();
            };    

            var displayValue = function(lot, key) {
              if (lot.home === undefined || lot.home === null) {
                return lot[key];
              } else {
                if (lot.home[key] === undefined || lot.home[key] === null) {
                  console.error("lot.home[" + key + "] was not found...");
                } else {
                  return lot.home[key];
                }
              }
            };

            var chooseElevation = function(lot) {

              if (lot.home && lot.home.elevation && lot.home.elevation.img.medium !== undefined) {
                return lot.home.elevation.img.medium;
              }

              if (lot.home && lot.home.residence && lot.home.residence.elevations[0] !== undefined && lot.home.residence.elevations[0].img.medium !== undefined) {
                return lot.home.residence.elevations[0].img.medium;
              }

              // really really default
              return "/images/site/microsite/site-plan-popover-thumb.png";

            };
    
            $scope.lots = [];
            angular.forEach($scope.home.community.lot_map.lots, function(lot) {
              $scope.lots.push(populateLotTemplateObject(lot));
            });

        });

      }
    ])
  ;

})();
