;(function(){

    'use strict';


    angular
    .module("Milestone")

    .controller("ComingSoonCtrl", ["$scope", "$http", "$modal", "$timeout", "uiGmapPromises", "CommunitiesListDataService", "ComingSoonCommunitiesListDataService",
        function($scope, $http, $modal, $timeout, uiGmapPromises, CommunitiesListDataService, ComingSoonCommunitiesListDataService) {

            $scope.regions = CommunitiesListDataService.regions;
            $scope.comingSoonCommunities = ComingSoonCommunitiesListDataService.communities;

            var austin = {
              latitude: 30.3298767,
              longitude: -97.6948925
            };

            uiGmapPromises.GoogleMapsApiReady()
              .then(function init(GoogleMaps) {

                $scope.map = {
                  center: austin,
                  control: {},
                  markers: [],
                  zoom: 9,
                  options: {
                    streetViewControl: false,
                    draggable: true,
                    scrollwheel: true,
                    mapTypeControl: false,
                    panControl: true,
                    panControlOptions: {
                      position: GoogleMaps.ControlPosition.RIGHT_CENTER
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                      position: GoogleMaps.ControlPosition.RIGHT_CENTER
                    },
                  },
                  markersEvents: {
                    click: function(marker, eventName, model, args) {
                      $scope.map.window.options.pixelOffset = new GoogleMaps.Size(0, -50);
                      $scope.map.window.coords = marker.center;
                      hideWindows();
                      $scope.map.window.marker = marker;
                      $scope.map.window.model = model;
                      $scope.map.window.show = true;
                    }
                  },
                  window: {
                    marker: {},
                    show: false,
                    closeClick: function() {
                      this.show = false;
                      this.model = {};
                    },
                    options: {
                      maxWidth: 800
                    }
                  }
                };

                uiGmapPromises.GoogleMapsIsAllReady()
                .then(function(theMap) {
                  $timeout(function() {
                    GoogleMaps.event.trigger(theMap, 'resize');

                    angular.forEach($scope.comingSoonCommunities, function(community) {

                      var marker = {
                        id: community.id,
                        name: community.name,
                        latitude: community.latitude,
                        longitude: community.longitude,
                        community: community,
                        icon: '/images/site/blue-icon-pin.png',
                        options: {
                          visible: true
                        }
                      };

                      $scope.map.markers.push(marker);
                    });

                    fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                  }, 3000);
                });

              });

            var addMarkers = function() {
              angular.forEach($scope.comingSoonCommunities, function(community) {

                var marker = {
                  id: community.id,
                  name: community.name,
                  latitude: community.latitude,
                  longitude: community.longitude,
                  community: community,
                  icon: '/images/site/blue-icon-pin.png',
                  options: {
                    visible: true
                  }
                };

                $scope.map.markers.push(marker);
              });
            };

            var hideWindows = function() {
              // hide any windows
              $scope.map.window.show = false;
              $scope.map.window.marker = null;
              $scope.map.window.model = null;
            };

            var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {
              var newBounds = new GoogleMaps.LatLngBounds();

              angular.forEach($scope.map.markers, function(marker) {
                newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
                newBounds.extend(new GoogleMaps.LatLng(0.005 + (1 * marker.latitude), 0.005 + (1 * marker.longitude)));
              });

              theMap.fitBounds(newBounds);

              if (theMap.getZoom() > 13) {
                theMap.setZoom(13);
              }
            };

            // Redirect to selected community
            $scope.$watch("redirect.community", function(value, oldValue) {
                if (value == oldValue || _.isEmpty(value)) { return; }
                var selected = {};


                _.map($scope.regions, function (r) {
                    _.map(r.communities, function(c) {
                        if (c.id == value) {
                            selected.region = r.seo_name;
                            selected.community = c.seo_name;
                        }
                    });
                });

                window.location = '/' + selected.region + '/' + selected.community;
            });

            $scope.open = function(object) {
                $scope.modalCommunity = object;
                var modalInstance = $modal.open({
                    templateUrl: 'comingSoonModal.html',
                    controller: 'comingSoonModalCtrl',
                    windowClass: 'modal-auto-size fade',
                    animation: true,
                    size: 'md',
                    scope: $scope
                });
            };

        }
    ])

    ;

})();