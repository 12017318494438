(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").controller("faqCtrl", ["$scope",
    function($scope) {

      //FAQ
      $scope.faqs = [{
      	'title': 'Where does MileStone have communities? ',
        'body': 'We currently have communities in <a href="https://www.mymilestone.com/downtown-south-austin" target="_blank">North Austin/Cedar Park</a>, <a href="https://www.mymilestone.com/downtown-south-austin" target="_blank">Downtown/South Austin</a>, <a href="https://www.mymilestone.com/buda" target="_blank">Buda</a> and <a href="https://www.mymilestone.com/hill-country" target="_blank">the Hill Country</a>. We also have a page set aside for communities that are <a href="https://www.mymilestone.com/comingsoon">coming soon</a>!'
      },{
      	'title': 'What is MileStone Community Builders?',
      	'body': 'MileStone is a central Texas builder that builds homes for Texans built by Texans. We love where we live and strive to ensure our communities work with the areas in which they are built and help to improve the overall community. For more information about our vision, our team, our awards and our clients check out the links below: <br><br>'
      	+'Vision:  <br class="visible-xs"><a href="https://www.mymilestone.com/why-milestone/more-about-milestone" target="_blank">https://www.mymilestone.com/why-milestone/more-about-milestone</a><br>'
      	+'Team:  <br class="visible-xs"><a href="https://www.mymilestone.com/why-milestone/the-team" target="_blank">https://www.mymilestone.com/why-milestone/the-team</a><br>'
      	+'Awards:  <br class="visible-xs"><a href="https://www.mymilestone.com/why-milestone/awards" target="_blank">https://www.mymilestone.com/why-milestone/awards</a><br>'
      	+'Testimonials:  <br class="visible-xs"><a href="https://www.mymilestone.com/why-milestone/testimonials" target="_blank">https://www.mymilestone.com/why-milestone/testimonials</a>'
      },{
      	'title': 'How can I find out more about the home buying process? ',
      	'body': 'We do! Check out our Buying Process page which also includes information about our preferred lenders. '
      },{
      	'title': 'Do you have a resource for the home buying process?',
      	'body': 'We do! Check out our <a href="https://www.mymilestone.com/buying-milestone/buying-process" target="_blank">Buying Process page</a> which also includes information about our preferred lenders.'
      },{
      	'title': 'Am I able to customize my home?',
      	'body': 'Yes! Within 2 weeks of completing the contract of your new home you can make an appointment with Jessica Nixon Interior Design. For more information, visit our <a href="https://www.mymilestone.com/buying-milestone/designing-your-milestone-home" target="_blank">Designing Your MileStone home page</a>.'
      },{
      	'title': 'Is there a way for me to save and organize the homes that I like?',
      	'body': 'We have a feature on this site that allows you to favorite homes. To sign up for this free service visit our <a href="https://www.mymilestone.com/my-saved-homes/login" target="_blank">My Saved Homes page</a> to get started!'
      },{
      	'title': 'Is there a way to get more information on each community? ',
      	'body': 'Yes! Each community has its own page with information about the community’s location, available floorplans and lots, amenities and more!'
        +'<h3>North Austin/Cedar Park Communities</h3>'
        +'<p>Bonnet: <br class="visible-xs"><a href="https://www.mymilestone.com/leander-and-liberty-hill/bonnet" target="_blank">https://www.mymilestone.com/leander-and-liberty-hill/bonnet</a> <br>'
        +  'Larkspur: <br class="visible-xs"><a href="https://www.mymilestone.com/leander-and-liberty-hill/larkspur" target="_blank">https://www.mymilestone.com/leander-and-liberty-hill/larkspur</a> <br>'
        +  'The Enclave at Lagos: <br class="visible-xs"><a href="https://www.mymilestone.com/manor/the-enclave-at-lagos" target="_blank">https://www.mymilestone.com/manor/the-enclave-at-lagos</a> <br>'
        +  'The Meadows at Quick Ranch: <br class="visible-xs"><a href="https://www.mymilestone.com/round-rock/the-meadows-at-quick-ranch" target="_blank">https://www.mymilestone.com/round-rock/the-meadows-at-quick-ranch</a> <br>'
        +'<h3>Downtown/South Austin Communities</h3>'
        +'<p>Messing Village: <br class="visible-xs"><a href="https://www.mymilestone.com/downtown-south-austin/messinger-village" target="_blank">https://www.mymilestone.com/downtown-south-austin/messinger-village</a> <br>'
        +  'Villas of Austin: <br class="visible-xs"><a href="https://www.mymilestone.com/downtown-south-austin/vistas-of-austin" target="_blank">https://www.mymilestone.com/downtown-south-austin/vistas-of-austin</a> <br>'
        +'<h3>Buda Communities</h3>'
        +'<p>Crosswinds: <br class="visible-xs"><a href="https://www.mymilestone.com/buda-and-kyle/crosswinds" target="_blank">https://www.mymilestone.com/buda-and-kyle/crosswinds</a> <br>'
        +  'Sage Hollow: <br class="visible-xs"><a href="https://www.mymilestone.com/buda-and-kyle/sage-hollow" target="_blank">https://www.mymilestone.com/buda-and-kyle/sage-hollow</a></p>'
        +'<h3>Dripping Springs Communities</h3>'
        +'<p>Skyridge: <br class="visible-xs"><a href="https://www.mymilestone.com/dripping-springs/skyridge" target="_blank">https://www.mymilestone.com/dripping-springs/skyridge</a></p>'
      },{
      	'title': 'Do you have any special discounts for new home buyers?',
      	'body': 'Our Hometown Heroes is a program we created to celebrate local residents who do good for their communities. Their good deeds can help then earn up to $2000 off of their new home purchase. For more information, visit our Hometown Heroes page <a href="https://www.mymilestone.com/why-milestone/hometown-heroes" target="_blank">here</a>.'
      },{
      	'title': 'How can I work for MileStone?',
      	'body': 'Be sure to check on our <a href="https://www.mymilestone.com/careers" target="_blank">Careers page</a> for any current job listings'
      }];

    }
  ]);

})();
