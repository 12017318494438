angular
  .module("Milestone")

  .controller('regionalMapCtrl', ['$scope', '$q', 'uiGmapPromises', 'RegionsDataService', 'device', '$timeout',
    function($scope, $q, uiGmapPromises, RegionsDataService, device, $timeout) {
      'use strict';

      uiGmapPromises.GoogleMapsApiReady()
        .then(function init(GoogleMaps) {

          $scope.map = {
            center: {
              latitude: 30.262,
              longitude: -97.934
            },
            control: {},
            markers: [],
            zoom: 10,
            options: {
              streetViewControl: false,
              draggable: true,
              scrollwheel: true,
              mapTypeControl: false,
              panControl: true,
              panControlOptions: {
                position: GoogleMaps.ControlPosition.RIGHT_CENTER
              },
              zoomControl: true,
              zoomControlOptions: {
                position: GoogleMaps.ControlPosition.RIGHT_CENTER
              }
            },
            markersEvents: {
              click: function(marker, eventName, model, args) {
                $scope.map.window.coords = marker.center;
                $scope.map.window.show = false;
                $scope.map.window.marker = marker;
                $scope.map.window.model = model;
                $scope.map.window.show = true;
              }
            },
            window: {
              marker: {},
              show: false,
              closeClick: function() {
                this.show = false;
                this.model = {};
              },
              options: {
                pixelOffset: new GoogleMaps.Size(0, -50),
                maxWidth: 800
              }
            }
          };

          RegionsDataService.regions
            .then($scope.resizeMap)
            .then(addMarkers)
            .then(function(){

              uiGmapPromises.GoogleMapsIsAllReady()
                .then(function(theMap) {

                  GoogleMaps.event.addListenerOnce(theMap, 'bounds_changed', function(event) {
                    if (this.getZoom() > 14) {
                      this.setZoom(14);
                    }
                  }); 

                  GoogleMaps.event.addDomListener(window, "resize", function() {
                    $scope.resizeMap();
                    fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                  });

                  $scope.map.window.options.pixelOffset = new GoogleMaps.Size(0, -50);

                  // Force this to the next $digest
                  $timeout(function(){
                    $scope.resizeMap();
                    fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                  }, 1);

                });

            });

        });

      $scope.resizeMap = function() {
        var mapsize = $('.map-section').height();
        var mapContainerOffset = mapsize + $(".navbar-fixed-top").height();

        $('.search-results-section').css('margin-top', mapContainerOffset);
      };

      var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {
        var newBounds = new GoogleMaps.LatLngBounds();

        angular.forEach($scope.map.markers, function(marker) {
          newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
        });

        theMap.fitBounds(newBounds);
      };

      var addMarkers = function() {
        var region = $scope.region;
        console.log(region.communities);
        angular.forEach(region.communities, function(community) {

          var marker = {
            id: community.id,
            name: community.name,
            latitude: community.latitude,
            longitude: community.longitude,
            community: community,
            icon: '/images/site/blue-icon-pin.png',
            options: {
              visible: true
            }
          };

          $scope.map.markers.push(marker);
        });
      };

    }

  ])
;
